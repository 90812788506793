import { InjectionToken } from '@angular/core';
import { environment } from '@common/co/environment';

export const APP_CONFIG = new InjectionToken<IAppConfig>('appConfig', {
  factory: (): Readonly<IAppConfig> => appConfig,
});

interface IAppConfig_AppInsights {
  instrumentationKey: string;
}

export interface IAppConfig {
  apiUrl: string;
  identityServerUrl: string;
  isProduction: boolean;
  isHmr: boolean;
  appInsights: IAppConfig_AppInsights;
  documentsSAConnectionString: string;
  spltempsaConnectionString: string;
  version: string;
  signalrHubUrl: string;
  bitmovinPlayerKey: string;
}

export const appConfig: Readonly<IAppConfig> = {
  apiUrl: environment.apiUrl,
  identityServerUrl: environment.identityServerUrl,
  isProduction: environment.production,
  isHmr: environment.hmr,
  documentsSAConnectionString: environment.documentsSAConnectionString,
  spltempsaConnectionString: environment.spltempsaConnectionString,
  appInsights: {
    instrumentationKey: environment.appInsights.instrumentationKey,
  },
  version: '0.15.0.37',
  signalrHubUrl: 'hubs/bo',
  bitmovinPlayerKey: environment.bitmovinPlayerKey,
};
